<template>
    <div class="page-rooter-fff">
        <headeBar title="使用情况" left-arrow @click-left="newAppBack"/>
        <div class="page-content">
            <van-list
                class="list"
                v-if="selfFitnessEquityRecordList.length"
                :finished="finished"
                :offset="200"
                @load="onLoad"
                :immediate-check="false"
                finished-text="没有更多了"
                v-model="loading"
            >
                <div class="item" @click="jump(item)" v-for="(item, index) in selfFitnessEquityRecordList" :key="index">
                    <div class="item-r">
                        <div class="item-r-l">
                            <div>{{ item.venueName }}</div>
                            <div>{{ item.createTime }} 使用了 <span>{{ item.ticketUseTime || 0 }}</span> 分钟</div>
                            <!--              <div v-else-if="item.equityType === 1">{{ item.createTime }}  使用了 <span>权益礼包</span> 分钟</div>-->
                        </div>
                    </div>

                    <div class="item-l">
                        <van-icon name="arrow" size="3.2vw"/>
                    </div>
                </div>
            </van-list>
            <div v-else class="empty">
                <img
                    src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/WeChat/defaultPic/empty.png"
                    alt="">
                <div>暂无数据</div>
            </div>
        </div>

    </div>
</template>

<script>
import appMixin from "@/mixin/appMixin";
import {newAppBack, getUserIdAndToken, defaultApp, gotoAppPage} from "@/lib/appMethod";
import headeBar from '@/components/app/headBar';
import wx from "weixin-js-sdk";

export default {
    data() {
        return {
            finished: false,
            selfFitnessEquityRecordList: [],
            loading: false,
            refreshing: false,
            active: 0,
            pageNum: 1,
            pageSize: 10,
        };
    },
    mixins: [appMixin],
    components: {headeBar},
    created() {
        this.load();
    },
    mounted() {
    },
    methods: {
        // 页面初次加载
        async load() {
            try {
                this.param = await getUserIdAndToken(this.baseURLApp)
            } catch (err) {
                if (this.isTestEnvironment) {
                    this.param = {userId: 367402, token: '7DgH3EuZIvjlLhfXTyy'}
                }
                console.log("不是APP环境环境错误");
            }
            Object.assign(this.param, {userEquityCardId: this.$route.query.userMembershipCardId ?? ''})
            this.getList()
        },
        onLoad() {
            this.getList()
        },
        onRefresh() {
            this.getList(true)
        },
        getList(isReset) {
            isReset && (this.page = 1)
            this.$axios.post(`${this.baseURLApp}/user/equityCard/usage/record/page`, Object.assign({
                pageNum: this.pageNum,
                pageSize: this.pageSize
            }, this.param)).then(r => {
                let arr = r.data.records || []
                this.finished = arr.length < this.pageSize
                this.loading = false
                if(isReset){
                    this.selfFitnessEquityRecordList = []
                    this.refreshing = false
                }
                this.selfFitnessEquityRecordList = this.selfFitnessEquityRecordList.concat(arr)
                this.pageNum ++
            })
        },
        jump(item) {
            // if(item.equityType === 1){
            //     gotoAppPage(7, {payOrderNo: item.outRecordId})
            //     return false
            // }
            if (this.appTypeStr === 'mini') {
                wx.miniProgram.navigateTo({
                    url: `/pages/webView/index?webUrl=${this.baseURL}/appWeb/userModule/costDetail/index.html&userId=${this.param.userId}&ticketId=${item.outRecordId}`
                })
            } else {
                defaultApp();
                window.location.href = `${this.baseURL}/appWeb/userModule/costDetail/index.html?userId=${this.param.userId}&ticketId=${item.outRecordId}&isBackToH5=true`
            }
        },
        newAppBack
    },
};
</script>

<style lang="less" scoped>
.page-rooter-fff {
    background: #F5F5F5;
    height: 100vh;
    display: flex;
    flex-direction: column;
    .page-content{
      flex-grow: 1;
      overflow-y: auto;
    }
    .com_headerbar{
      flex-shrink: 0;
    }
}


.list {
    height: 100%;
    width: 100%;
    padding: 32px;
    box-sizing: border-box;
    overflow: auto;

    .item + .item {
        margin-top: 32px;
    }

    .item {
        display: flex;
        background-color: white;
        border-radius: 8px;

        .item-l {
            color: #9AA1A9;
            display: flex;
            align-items: center;
            padding-right: 32px;
        }

        .item-r {
            display: flex;
            padding: 32px;
            justify-content: space-between;
            flex-grow: 1;

            .item-r-l {
                color: #242831;

                div:first-child {
                    font-weight: bold;
                    font-size: 24px;
                }

                div:last-child {
                    font-size: 20px;
                    margin-top: 16px;
                    color: #6C727A;

                    span {
                        color: #FF6E00;
                        font-weight: bold;
                    }
                }
            }

            .item-r-r {
                width: 108px;
                height: 40px;
                background: #242831;
                border-radius: 20px;
                color: white;
                font-weight: bold;
                font-size: 20px;
                text-align: center;
                line-height: 40px;
                margin-top: 32px;
            }
        }

    }

}

.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    min-height: 90vh;

    img {
        width: 160px;
        height: 160px;
    }

    div {
        margin-top: 12px;
        font-size: 24px;
        color: #999;
    }
}

/deep/ .van-tab--active {
    font-weight: bold;
}

/deep/ .van-tabs {
    height: 100%;
}

/deep/ .van-hairline--top-bottom::after {
    border-top: none;
    border-color: #eee;
}

/deep/ .van-tabs__content, /deep/ .van-tab__pane {
    height: calc(100% - 44px);
}
</style>
